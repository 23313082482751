// i18next-extract-mark-ns-start products-sabr-geometry

import { SABR_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductSabrPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							GEAR offers a seamless integration to Ohio State
							University GEARLab – Load Distribution Program (LDP4)
							and to Dontyne’s Load Analysis Model. &nbsp;These
							calculate the deformation of gear tooth components and
							their effects on properties such as stress and
							transmission error under load. &nbsp;With this
							integration, the gearbox design process is significantly
							enhanced - taking the designer from design layout,
							through gearbox and gear macro geometry analysis to
							assessing the effects of misalignments and
							micro-geometry on transmission error, surface contact
							stress, flash temperatures and lubricant film
							thicknesses.
						</p>
						<p>
							For any number of load cases, SABR calculates the
							misalignments and passes these to GEAR for macro
							geometry gear design and rating. &nbsp;All duty cycle,
							macro geometry and misalignment information can then be
							integrated into the micro geometry load analysis model
							for detailed tooth contact analysis.
						</p>
						<p>
							GEAR also includes a built in micro-geometry,
							transmission error and contact stress analysis, allowing
							the design and rating of the gear pair’s micro-geometry.
						</p>
						<p>
							For more information on LDP4, please visit:{' '}
							<a href="https://gearlab.osu.edu/windowsldp">
								https://gearlab.osu.edu/windowsldp
							</a>
						</p>
						<p>
							For more information on Dontyne's Load Analysis Model,
							please visit:{' '}
							<a href="http://www.dontynesystems.com">
								http://www.dontynesystems.com
							</a>
						</p>
					</Trans>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{SABR_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductSabrPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-sabr-geometry", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/sabr-hero.jpg" }) {
		...imageBreaker
	}
}
`;
